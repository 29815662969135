import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Image from 'next/image';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  WhatsAppShareButton,
  TelegramShareButton,
  LinkedinShareButton,
} from 'components/blog/buttons';
import Button from 'seedly-component-library/button-new';
import Dialog from 'seedly-component-library/dialog';
import {
  openPostModalForSharing,
  closeShareModal as closeShareModalAction,
} from 'dispatcher/modalDispatcher';
import { SHARE_TYPE, SHARE_TITLE } from 'constants/app';
import { RootState } from 'reducer';
import { imageUrl } from 'theme';

const SocialButtonSection = () => {
  const link = useSelector((state: RootState) => state.share.link);

  return (
    <div className="flex items-center gap-4">
      <div className="cursor-pointer">
        <FacebookShareButton url={link} size="22" />
      </div>
      <div className="cursor-pointer">
        <LinkedinShareButton url={link} size="22" />
      </div>
      <div className="cursor-pointer">
        <TelegramShareButton url={link} size="23" />
      </div>
      <div className="cursor-pointer">
        <WhatsAppShareButton url={link} size="22" />
      </div>
    </div>
  );
};

const ShareLinkSection = () => {
  const link = useSelector((state: RootState) => state.share.link);
  const [copied, setCopied] = useState(false);

  return (
    <div className="px-4 py-3 border border-neutral-400 rounded w-full max-w-[393px] relative bg-neutral-200">
      <p className="whitespace-nowrap text-xs overflow-x-hidden">{link}</p>
      <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
        <div className="bg-white absolute right-0 top-0 px-4 py-3 cursor-pointer">
          <p className="text-xs text-blue-500">{copied ? 'Copied!' : 'Copy'}</p>
        </div>
      </CopyToClipboard>
    </div>
  );
};

const ShareForm = () => {
  const link = useSelector((state: RootState) => state.share.link);
  const type = useSelector((state: RootState) => state.share.type);
  const data = useSelector((state: RootState) => state.share.data);
  const isShareModalOpen = useSelector(
    (state: RootState) => state.modal.isShareModalOpen,
  );

  const headerTitle = type && `${SHARE_TITLE[type]}`;

  const dispatch = useDispatch();

  const closeShareModal = () => {
    dispatch(closeShareModalAction());
  };

  let displayImage;

  if (type === SHARE_TYPE.AFTERPOSTOPINION) {
    displayImage = (
      <Image
        src={imageUrl.paperPlane}
        alt="Post Success"
        height={188}
        width={188}
      />
    );
  } else if (type === SHARE_TYPE.ARTICLE) {
    displayImage = (
      <Image src={data.imageUrl} alt="Blog Image" height={168} width={320} />
    );
  } else {
    displayImage = (
      <Image
        src={imageUrl.referFriendLogo}
        alt="Refer a friend logo"
        height={74}
        width={83}
      />
    );
  }

  return (
    <Dialog
      isOpen={isShareModalOpen}
      onCloseClick={closeShareModal}
      headerTitle={headerTitle}
    >
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-4 w-full items-center">
          <div className="mt-8 mb-4">{displayImage}</div>
          {type === SHARE_TYPE.ARTICLE ? (
            <p className="text-base font-bold text-center">{data.title}</p>
          ) : (
            <>
              <div className="flex flex-col w-full gap-1 items-center">
                {type === SHARE_TYPE.AFTERPOSTOPINION && (
                  <p className="text-base font-bold">
                    Your opinion is published!
                  </p>
                )}
                <p>Share to your groups for better discoverability</p>
              </div>
              <Button
                size="md"
                isBlock
                onClick={() => {
                  dispatch(closeShareModalAction());
                  dispatch(openPostModalForSharing(link));
                }}
              >
                Post to Group
              </Button>
            </>
          )}
          <div className="horizontal-divider" />
          <p>Share via:</p>
          <SocialButtonSection />
          <ShareLinkSection />
        </div>
      </div>
    </Dialog>
  );
};

export default ShareForm;
