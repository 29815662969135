import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import { Facebook as Icon } from 'react-feather';
import { Colors, Tooltip } from 'app/seedly-component-library';
import { FacebookShareButton as ShareButton } from 'react-share';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import { useAmp } from 'next/amp';

const Wrapper = styled.div`
  cursor: pointer;
  svg {
    stroke-width: 0.1px;
    path {
      fill: ${props => props.theme.colors.neutral7};
    }

    :hover {
      path {
        fill: ${props => props.theme.colors.seedlyBlue};
      }
    }
  }
`;

const Button = ({ size, url }) => {
  const isAmp = useAmp();

  // Selectors
  const isMobile = useIsMobile();

  if (isMobile) {
    if (isAmp) {
      return (
        <Wrapper>
          <a
            title="facebook share"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.facebook.com/sharer.php?u=${url}/`}
          >
            <Icon size={size || '24'} color={Colors.neutral7} />
          </a>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <ShareButton url={url}>
          <Icon size={size || '24'} color={Colors.neutral7} />
        </ShareButton>
      </Wrapper>
    );
  }

  return (
    <Tooltip
      showToolTip
      placement="top"
      renderTriggerButton={() => {
        return (
          <Wrapper>
            <ShareButton url={url}>
              <Icon size={size || '24'} color={Colors.neutral7} />
            </ShareButton>
          </Wrapper>
        );
      }}
      renderToolTipBody={() => (
        <div style={{ width: '150px', flexWrap: 'wrap' }}>
          Share on Facebook
        </div>
      )}
    />
  );
};

const FacebookShareButton = ({ size, url }) => {
  return (
    <div
      onClick={() => {
        mixpanel.track(trackedEvents.ShareBar, {
          param: 'facebook',
        });
      }}
      id="fb-share-button"
    >
      <Button size={size} url={url} />
    </div>
  );
};

FacebookShareButton.propTypes = {
  size: PropTypes.string,
  url: PropTypes.string.isRequired,
};

FacebookShareButton.defaultProps = {
  size: '24',
};

export default FacebookShareButton;
