import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'app/seedly-component-library';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import { WhatsappShareButton as ShareButton } from 'react-share';
import { useAmp } from 'next/amp';

const Wrapper = styled.div`
  cursor: pointer;
`;
const Svg = styled.svg`
  path {
    fill: ${props => props.theme.colors.neutral7};
  }
  :hover {
    path {
      fill: ${props => props.theme.colors.seedlyBlue};
    }
  }
`;

const Icon = ({ size = '24px' } = {}) => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40', width: size, height: size }}
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M20,1L20,1C9.5,1,1,9.5,1,20c0,4.2,1.3,8,3.6,11.1l-2.4,7.1l7.3-2.3c3,2,6.6,3.1,10.5,3.1c10.5,0,19-8.5,19-19
S30.5,1,20,1z M31.1,27.8c-0.5,1.3-2.3,2.4-3.7,2.7c-1,0.2-2.3,0.4-6.7-1.4c-5.6-2.3-9.2-8-9.5-8.4C10.9,20.4,9,17.7,9,15
s1.4-4.1,1.9-4.6c0.5-0.5,1.2-0.7,1.9-0.7c0.2,0,0.4,0,0.6,0c0.6,0,0.8,0.1,1.2,0.9c0.5,1.1,1.6,3.8,1.7,4.1
c0.1,0.3,0.3,0.7,0.1,1c-0.2,0.4-0.3,0.5-0.6,0.9c-0.3,0.3-0.5,0.6-0.8,0.9c-0.3,0.3-0.5,0.6-0.2,1.2c0.3,0.5,1.4,2.4,3.1,3.8
c2.1,1.9,3.8,2.5,4.5,2.7c0.5,0.2,1,0.1,1.3-0.2c0.4-0.5,0.9-1.2,1.5-2c0.4-0.5,0.9-0.6,1.4-0.4c0.5,0.2,3.2,1.5,3.8,1.8
c0.6,0.3,0.9,0.4,1.1,0.6C31.5,25.4,31.5,26.5,31.1,27.8z"
        />
      </g>
    </g>
  </Svg>
);

const Button = ({ size, url }) => {
  const isAmp = useAmp();

  // Selectors
  const isMobile = useIsMobile();

  if (isMobile) {
    if (isAmp) {
      return (
        <Wrapper>
          <a
            title="whatsapp share"
            target="_blank"
            rel="noopener noreferrer"
            href={`whatsapp://send?text=${url}`}
          >
            <Icon size={`${size}px`} />
          </a>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <ShareButton url={url}>
          <Icon size={`${size}px`} />
        </ShareButton>
      </Wrapper>
    );
  }

  return (
    <Tooltip
      showToolTip
      placement="top"
      renderTriggerButton={() => {
        return (
          <Wrapper>
            <ShareButton url={url}>
              <Icon size={`${size}px`} />
            </ShareButton>
          </Wrapper>
        );
      }}
      renderToolTipBody={() => (
        <div style={{ width: '150px', flexWrap: 'wrap' }}>
          Share on Whatsapp
        </div>
      )}
    />
  );
};

const WhatsAppShareButton = ({ size, url }) => {
  return (
    <div
      onClick={() => {
        mixpanel.track(trackedEvents.ShareBar, {
          param: 'whatsapp',
        });
      }}
      id="wa-share-button"
    >
      <Button size={size} url={url} />
    </div>
  );
};

WhatsAppShareButton.propTypes = {
  size: PropTypes.string,
  url: PropTypes.string.isRequired,
};

WhatsAppShareButton.defaultProps = {
  size: '24',
};

export default WhatsAppShareButton;
