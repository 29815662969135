import React from 'react';
import styled from 'styled-components';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import { Linkedin as Icon } from 'react-feather';
import { Colors, Tooltip } from 'app/seedly-component-library';
import { LinkedinShareButton as ShareButton } from 'react-share';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import { useAmp } from 'next/amp';

const Wrapper = styled.div`
  cursor: pointer;
  svg {
    :hover {
      stroke  ${props => props.theme.colors.seedlyBlue};
    }
  }
`;

const Button = ({ size, url }) => {
  const isAmp = useAmp();

  // Selectors
  const isMobile = useIsMobile();

  if (isMobile) {
    if (isAmp) {
      return (
        <Wrapper>
          <a
            title="linkedin share"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          >
            <Icon size={size || '24'} color={Colors.neutral7} />
          </a>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <ShareButton url={url}>
          <Icon size={size || '24'} color={Colors.neutral7} />
        </ShareButton>
      </Wrapper>
    );
  }

  return (
    <Tooltip
      showToolTip
      placement="top"
      renderTriggerButton={() => {
        return (
          <Wrapper>
            <ShareButton url={url}>
              <Icon size={size || '24'} color={Colors.neutral7} />
            </ShareButton>
          </Wrapper>
        );
      }}
      renderToolTipBody={() => (
        <div style={{ width: '150px', flexWrap: 'wrap' }}>
          Share on Linkedin
        </div>
      )}
    />
  );
};

const LinkedinShareButton = ({ size, url }) => {
  return (
    <div
      onClick={() => {
        mixpanel.track(trackedEvents.ShareBar, {
          param: 'linkedin',
        });
      }}
      id="linkedin-share-button"
    >
      <Button size={size} url={url} />
    </div>
  );
};

export default LinkedinShareButton;
