import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'app/seedly-component-library';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import { TelegramShareButton as ShareButton } from 'react-share';
import { useAmp } from 'next/amp';

const Wrapper = styled.div`
  cursor: pointer;
`;
const Svg = styled.svg`
  path {
    fill: ${props => props.theme.colors.neutral7};
  }
  :hover {
    path {
      fill: ${props => props.theme.colors.seedlyBlue};
    }
  }
`;

const Icon = ({ size = '25px' } = {}) => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40', width: size, height: size }}
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M38.4,15.4c-0.5-1.8-1.2-3.5-2.2-5.1c-0.8-1.3-1.6-2.5-2.6-3.6c-0.6-0.7-1.4-1.3-2.1-1.8c-2.2-1.7-4.7-2.8-7.4-3.4
 C22.9,1.2,21.6,1,20.4,1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.4,0c0,0-0.1,0-0.1,0c-1.8,0-3.6,0.3-5.3,0.9c-2.5,0.8-4.8,2-6.8,3.7
 C6.3,6.7,5.2,8,4.2,9.5c-1.3,1.9-2.2,4.1-2.7,6.3c-0.2,1-0.4,2-0.4,3.1C1,20,1,21.1,1.1,22.2c0.2,1.6,0.6,3.1,1.2,4.6
 c0.7,1.9,1.7,3.7,3,5.3c0.9,1.1,2,2.1,3.2,3c1.6,1.2,3.4,2.2,5.3,2.8c1.1,0.4,2.2,0.7,3.3,0.8c1.4,0.2,2.8,0.3,4.2,0.2
 c1.9-0.1,3.7-0.6,5.4-1.2c2.5-1,4.7-2.3,6.5-4.2c1.1-1.1,2-2.3,2.8-3.6c1.1-1.9,2-3.9,2.4-6c0.2-1.1,0.4-2.2,0.4-3.3
 c0,0,0-0.1,0-0.1c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0-0.6C38.9,17.7,38.7,16.6,38.4,15.4z M29.3,14c-0.3,1.6-0.7,3.3-1,4.9
 c-0.4,2-0.9,4.1-1.3,6.1c-0.3,1.2-0.5,2.5-0.8,3.7c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.4-0.6,0.6-1,0.5c-0.2-0.1-0.5-0.2-0.7-0.3
 c-1.5-1.1-3-2.2-4.5-3.3c0,0,0,0-0.1-0.1c-0.4,0.4-0.8,0.8-1.3,1.2c-0.3,0.3-0.6,0.6-1,0.9c-0.2,0.2-0.5,0.4-0.9,0.5
 c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.3,0-0.4c0.1-1.2,0.2-2.4,0.3-3.7c0-0.2,0-0.5,0.1-0.7c0,0,0-0.1,0.1-0.1c1-0.9,1.9-1.8,2.9-2.7
 c1.9-1.8,3.9-3.5,5.8-5.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0-0.2-0.1-0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.2
 c-3.4,2.1-6.8,4.3-10.2,6.4c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0-0.1,0-0.2,0c-1.5-0.5-3-0.9-4.5-1.4C9.4,21,9.2,21,9,20.9
 c-0.3-0.2-0.4-0.4-0.2-0.7c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.2,0.9-0.4,1.4-0.6c2.9-1.1,5.8-2.3,8.7-3.4c2.8-1.1,5.6-2.2,8.5-3.3
 c0.1,0,0.3-0.1,0.4-0.1c0.4-0.1,0.8,0.2,1,0.7C29.4,13.4,29.4,13.7,29.3,14z"
      />
    </g>
  </Svg>
);

const Button = ({ size, url }) => {
  const isAmp = useAmp();

  // Selectors
  const isMobile = useIsMobile();

  if (isMobile) {
    if (isAmp) {
      return (
        <Wrapper>
          <a
            title="telegram share"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://telegram.me/share/url?url=${url}`}
          >
            <Icon size={`${size}px`} />
          </a>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <ShareButton url={url}>
          <Icon size={`${size}px`} />
        </ShareButton>
      </Wrapper>
    );
  }

  return (
    <Tooltip
      showToolTip
      placement="top"
      renderTriggerButton={() => {
        return (
          <Wrapper>
            <ShareButton url={url}>
              <Icon size={`${size}px`} />
            </ShareButton>
          </Wrapper>
        );
      }}
      renderToolTipBody={() => (
        <div style={{ width: '150px', flexWrap: 'wrap' }}>
          Share on Telegram
        </div>
      )}
    />
  );
};

const TelegramShareButton = ({ size, url }) => {
  return (
    <div
      onClick={() => {
        mixpanel.track(trackedEvents.ShareBar, {
          param: 'telegram',
        });
      }}
      id="telegram-share-button"
    >
      <Button size={size} url={url} />
    </div>
  );
};

TelegramShareButton.propTypes = {
  size: PropTypes.string,
  url: PropTypes.string.isRequired,
};

TelegramShareButton.defaultProps = {
  size: '24',
};

export default TelegramShareButton;
